import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout/layout"
import Seo from "../components/Seo/seo"
import Heading from "../components/Heading/heading"
import ImageTextBlock from "../components/ImageTextBlock/imageTextBlock"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Sidan hittades inte" />
    <div style={{ paddingTop: '100px'}}>
    <Heading text="404" level="h1" position="fullwidth" paddingBottom />
    <ImageTextBlock
      text={[`Sidan du söker finns inte.`]}
      imagePosition="right"
      offset={2}
      columns={5}
      buttonTitle="Tillbaka till start"
      buttonLink="/"
      image={<StaticImage
        src="../images/placeholder.jpg"
        quality={95}
        formats={["AUTO", "WEBP"]}
        alt=""
        placeholder="blurred"
        layout="fullWidth"
      />}
    />
    </div>
  </Layout>
)

export default NotFoundPage
