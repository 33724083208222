import styled from "styled-components"
import { flexCenter, gridLayout } from "../../styles/mixins"
import { main } from "../../styles/theme"

export const Wrapper = styled.section`
  ${gridLayout}

  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? "30px" : "0")};

  @media ${main.breakpoints.medium} {
    padding-bottom: ${({ paddingBottom }) => (paddingBottom ? "60px" : "0")};
  }

  > * {
    grid-column: ${({ offset, number }) =>
      number ? "3 / span 10" : offset ? "2 / span 11" : "1 / span 12"};

    @media ${main.breakpoints.medium} {
      grid-column: ${({ position, offset }) =>
        position === "fullwidth"
          ? offset
            ? "2 / span 11"
            : "1 / span 12"
          : position === "left"
          ? offset
            ? "2 / span 5"
            : "1 / span 6"
          : offset
          ? "8 / span 5"
          : "7 / span 6"};
    }
  }
`
export const Dot = styled.span`
  ${flexCenter}
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.black};
  margin-right: 21px;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  align-self: center;
  grid-column: 1 / span 1;

  @media ${main.breakpoints.medium} {
    grid-column: ${({ position, offset }) =>
      position === "fullwidth"
        ? offset
          ? "2 / span 1"
          : "1 / span 1"
        : position === "left"
        ? offset
          ? "1 / span 1"
          : "1 / span 1"
        : offset
        ? "7 / span 1"
        : "6 / span 1"};
  }

  @media ${main.breakpoints.large} {
    width: 72px;
    height: 72px;
  }

  > h3 {
    color: ${({ theme, house }) =>
      house === 1 ? theme.colors.yellow : theme.colors.blue};
  }
`
