import React from "react"
import PropTypes from "prop-types"
import { H1, H2, H3, H4, H1style } from "../../styles/typography"
import { Wrapper, Dot } from "./styles"

const Heading = ({ text, level, position, offset, paddingBottom, number }) => {
  const heading = level => {
    switch (level) {
      case "h1":
        return <H1>{text}</H1>
      case "h2":
        return <H2>{text}</H2>
      case "h3":
        return <H3>{text}</H3>
      case "h4":
        return <H4>{text}</H4>
      case "h2withH1style":
        return <H1style>{text}</H1style>
      default:
        return <H2>{text}</H2>
    }
  }

  return (
    <Wrapper
      position={position}
      offset={offset ? 1 : 0}
      paddingBottom={paddingBottom ? 1 : 0}
      number={number}
    >
      {number && (
        <Dot house={number} position={position} offset={offset ? 1 : 0}>
          <H3>{number}</H3>
        </Dot>
      )}
      {heading(level)}
    </Wrapper>
  )
}

Heading.propTypes = {
  text: PropTypes.string.isRequired,
  level: PropTypes.string,
  position: PropTypes.string,
  offset: PropTypes.bool,
  paddingBottom: PropTypes.bool,
  number: PropTypes.number,
}

Heading.defaultProps = {
  level: "h2",
  position: "left",
  offset: true,
  paddingBottom: false,
}

export default Heading
